<template>
  <div v-if="mainShow" class="details">
    <v-dialog
      scrollable
      :value="showModal != 'false'"
      @click:outside="hideModal"
    >
      <v-card class="dialogMain">
        <div class="closeModalButton">
          <v-btn icon color="red" @click="hideModal">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <history
          v-if="showModal == 'history'"
          @title-voided="loadVehicle"
          :vin="vin"
          :controlNo="controlNo"
          @editSucceeded="handleEditSuccess"
          @scanModalToggle="handleScanModalToggle"
        />
        <plateHistory
          v-if="showModal == 'plateHistory'"
          isModal="true"
        ></plateHistory>
      </v-card>
    </v-dialog>

    <div>
      <div>
        <div v-if="showLinks == true" class="transaction-menu-container">
          <TransactionsMenu :isFromORBack="isFromORBack" />
        </div>
        <div class="vehicle-detail-container">
          <div id="detailsRight">
            <div class="top-buttons-div">
              <template v-if="undercoverVehicle">
                <v-dialog v-model="showUndercoverDialog" width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary top-button" v-on="on">
                      Remove Undercover
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>
                      Are you sure?
                    </v-card-title>

                    <v-card-text>
                      This will remove the undercover status from this vehicle.
                      <br />
                      <v-text-field
                        :error="
                          undercoverComment == undefined ||
                          undercoverComment.length == 0
                        "
                        autofocus
                        :messages="['required']"
                        v-model="undercoverComment"
                        label="comment"
                      />
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="showUndercoverDialog = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        :disabled="
                          undercoverComment == undefined ||
                          undercoverComment.length == 0
                        "
                        class="primary"
                        text
                        @click="undoUndercover"
                      >
                        I accept
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-else>
                <div>
                  <v-btn color="blue" @click="showModal = 'history'">
                    <span class="white-text">History</span>
                  </v-btn>

                  <v-btn
                    color="blue"
                    v-if="processObj.registration != undefined"
                    @click="showPlateHistory"
                  >
                    <span class="white-text">Plate History</span>
                  </v-btn>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue" v-on="on">
                        <span class="white-text">
                          Re-Print
                          <v-icon>mdi-chevron-down</v-icon>
                        </span>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="reprint('201')">
                        <v-list-item-title
                          >Title Application Form (201)</v-list-item-title
                        >
                      </v-list-item>
                      <template
                        v-if="
                          _.has(
                            processObj,
                            'registration.currentPlate.plateNo'
                          ) &&
                          processObj.registration.currentPlate.plateNo.length >
                            0
                        "
                      >
                        <v-list-item @click="reprint('decal')">
                          <v-list-item-title>
                            Registration Decal
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reprint('decal', true)">
                          <v-list-item-title>
                            Registration Decal (Letter Paper)
                          </v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-list-item @click="reprint('tdrSticker')">
                        <v-list-item-title>TDR Sticker</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </div>

            <h1>Detailed Information</h1>
            <div class="panel">
              <displayVehicleData
                :processObj="processObj"
                :readOnly="true"
              ></displayVehicleData>

              <div v-if="_.has(processObj, 'vehicle.stops')">
                <h4>Stops</h4>
                <div class="bootstrap">
                  <b-table
                    :no-border-collapse="true"
                    selectable
                    select-mode="single"
                    responsive="sm"
                    hover
                    :show-empty="true"
                    empty-text="No Stops"
                    :fields="stopTableFields"
                    head-variant="light"
                    sort-icon-left
                    :items="processObj.vehicle.stops"
                  >
                    <template v-slot:cell(recordDate)="data">
                      <template v-if="data.item.recordDate != undefined">
                        {{ data.item.recordDate.substr(0, 10) }}
                      </template>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="floating-footer">
        <div class="float-foot-item float-foot-left">
          <template v-if="$store.getters.resultsArray.length > 1">
            <v-btn color="red" @click="$router.push({ name: 'Results' })">
              <v-icon color="white">arrow_back</v-icon>
              <span class="white-text">Results</span>
            </v-btn>
          </template>
          <template v-if="previousRoute.name == 'MissingImages'">
            <v-btn color="red" @click="$router.push({ name: 'MissingImages' })">
              <v-icon color="white">arrow_back</v-icon>
              <span class="white-text"> Missing Images </span>
            </v-btn>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import displayVehicleData from "@/components/nonPageComponents/DisplayVehicleData";
import transactionMixin from "@/mixins/transaction.mixin.js";

import date from "@/mixins/date.mixin";
import { mapGetters } from "vuex";
import transactionTypes from "@/assets/jsonScaffolds/transactionTypes.json";
export default {
  props: {
    isFromORBack: {
      type: Boolean,
      required: false,
      default: () => false
    },
    vin: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    make: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    year: { type: [Number, String], default: null, required: false },
    undercoverVehicle: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: false
    },
    placard: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    }
  },
  mixins: [vehicleFunctions, date, transactionMixin],
  components: {
    displayVehicleData
  },
  data() {
    return {
      previousRoute: "",
      comment: "",
      comment2: "",
      showUndercoverDialog: false,
      undercoverComment: "",
      links: {},
      showModal: "false",
      showLinks: false,
      today: new Date(),
      mainShow: false,
      transObj: {},
      showTransactionWarning: false,
      isScanModalOpen: false,
      stopTableFields: [
        {
          key: "stopCode",
          label: "Stop",
          sortable: true,
          sortDesc: false
        },
        {
          key: "stopCodeDescription",
          label: "Description",
          sortable: true,
          sortDesc: false
        },
        {
          key: "recordDate",
          label: "Date",
          sortable: true,
          sortDesc: false
        },
        {
          key: "stopTypeCode",
          label: "Stop Type Code",
          sortable: true,
          sortDesc: false
        }
      ]
    };
  },
  methods: {
    setCancelTransactionAlert() {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Are You Sure?",
        minWidth: "350px",
        description: "You will lose all unsaved progress.",
        icon: "warning",
        actions: [
          {
            text: "Close",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
              this.$emit("finishDialogue", true);
            },
            color: "red"
          },
          {
            text: "Cancel",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
              this.$emit("finishDialogue", false);
            },
            color: "primary"
          }
        ]
      });
    },
    handleScanModalToggle(isScanModalOpen) {
      this.isScanModalOpen = isScanModalOpen;
    },
    async undoUndercover() {
      const voidComment = this.undercoverComment;
      const senddata = this._.cloneDeep(this.processObj);
      const timestamp = this.getCurrentTimestamp();

      if (this._.has(senddata, "vehicle.insurance")) {
        delete senddata.vehicle.insurance;
      }

      if (this._.has(senddata, "lessor")) {
        delete senddata.lessor;
      }

      if (this._.has(senddata, "liens")) {
        delete senddata.liens;
      }

      senddata.transaction = {
        transactionTimestamp: timestamp,
        invoiceNo: this.invoiceNo,
        comments: [voidComment]
      };

      for (let i = 0; i < senddata.owners.length; i++) {
        if (
          senddata.owners[i].individualCustomer.firstName == undefined ||
          senddata.owners[i].individualCustomer.firstName == ""
        )
          senddata.owners.splice(i, 1);
        if (this._.has(senddata.owners[i], "mailingAddress"))
          delete senddata.owners[i].mailingAddress;
      }

      if (this._.has(senddata, "title")) delete senddata.title;

      try {
        this.$root.$emit("setLoading", true);
        await this.$api.undercoverUndo(senddata);
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.$root.$emit("setLoading", false);
      }

      this.showUndercoverDialog = false;
      this.$store.dispatch("setGlobalAlertState", {
        title: "Saved",
        description: "Your transaction has been voided.",
        icon: "success",
        actions: [
          {
            text: "OK",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
              this.$router.push({ name: "Home" });
            },
            color: "primary"
          }
        ]
      });
    },
    async reprint(type, printOnLetter = false) {
      let vehicle;

      try {
        if (this._.has(this.processObj, "placard")) {
          vehicle = await this.$api.plateSearch(
            this.processObj.placard.controlNo,
            true,
            {
              204: () => {
                this.$root.$emit(
                  "push-alert",
                  "There was no vehicle history found.",
                  { color: "error" }
                );
              }
            }
          );
        } else {
          vehicle = await this.$api.vinSearch(
            this.processObj.vehicle.vin,
            true
          );
        }

        const documentToPrint = { doc: type, copies: 1 };
        if (printOnLetter) documentToPrint.index = 0;
        if (type == "tdrSticker") documentToPrint.index = 1;
        this.transObj = JSON.parse(vehicle[0].transactionJSON);
        this.$store.commit("transObj", this.transObj);
        this.printTransaction([documentToPrint], this.transObj, {
          authNo: vehicle[0].authNo
        });
      } catch (e) {
        console.error(e);
        this.$root.$emit("push-alert", "There was an issue printing.", {
          color: "error"
        });
      }
    },
    async showPlateHistory() {
      this.$root.$emit("setLoading", true);
      let plateHistoryData;
      try {
        const regParams = this.processObj.registration.currentPlate;
        plateHistoryData = await this.$api.plateHistory(
          regParams.plateNo,
          regParams.class,
          regParams.issueYear
        );
      } catch (e) {
        console.error(e);
        this.$root.$emit("setLoading", false);
        return false;
      }
      if (typeof plateHistoryData !== "object") {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Invalid!",
          description: "No results found",
          icon: "error"
        });
        this.$root.$emit("setLoading", false);
        return false;
      } else {
        this.$store.commit("plateHistory", plateHistoryData);
        this.$root.$emit("setLoading", false);
        this.showModal = "plateHistory";
      }
    },
    sortLienholders(lienholders) {
      const sorted = lienholders.slice(0);
      sorted.sort(function (a, b) {
        const x = a.lienPrecedenceNo;
        const y = b.lienPrecedenceNo;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return sorted;
    },
    hideModal() {
      this.showModal = "false";
    },
    async loadVehicle(usePrevResponse = false) {
      this.$root.$emit("setLoading", true);
      if (this.placard != "") {
        await this.$store.dispatch("VTRSPlacardInquiry", this.placard);
      } else {
        const vehicle = {
          vin: this.vin,
          makeCode: this.make,
          modelYear: parseInt(this.year)
        };
        await this.$store.dispatch("VTRSVehicleInquire", {
          vehicle,
          usePrevResponse
        });
      }
      // If the request errors or nothing is found
      if (this.VTRSVehicleRecord === null) {
        if (this.pendingTransactionRequest) {
          this.$store.commit(
            "transactionType",
            transactionTypes[
              this.pendingTransactionRequest.transaction.transactionType
            ]
          );
          this.$store.commit("processObj", this.newVehicle());
        } else {
          this.$router.push({ name: "Home" });
        }
      } else {
        this.$store.commit(
          "processObj",
          this.reformatTandrObjectforTransactionScreen(
            this._.cloneDeep(this.VTRSVehicleRecord)
          )
        );
        this.$store.commit("transObj", {});
        this.$store.commit("editObj", {});
      }
      this.$root.$emit("setLoading", false);
    },
    handleEditSuccess() {
      this.hideModal();
      this.loadVehicle();
    }
  },
  computed: {
    ...mapGetters({
      fuelArray: "fuelTypes",
      bodyTypesArray: "bodyTypes",
      colorsArray: "vehicleColors",
      vehicleTypesArray: "vehicleTypes",
      makesArray: "makes",
      edit: "userPermissions",
      processObj: "processObj",
      invoiceNo: "invoiceNo",
      pendingTransactionRequest: "pendingTransactionRequest",
      pendingTransaction: "pendingTransaction",
      VTRSVehicleRecord: "VTRSVehicleRecord"
    }),
    titleCode() {
      return this.$store.getters.titleCodes[this.$store.getters.titleCodeID];
    },
    controlNo() {
      return this._.get(this.processObj, "placard.controlNo") || "";
    }
  },
  watch: {
    "$route.path"() {
      this.loadVehicle();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vueComponentInstance => {
      vueComponentInstance.previousRoute = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isScanModalOpen) {
      this.setCancelTransactionAlert();

      this.$off("finishDialogue");
      this.$on("finishDialogue", isClosing => {
        if (isClosing) {
          next();
        }
      });
    } else {
      next();
    }
  },
  async created() {
    this.$store.commit("transactionType", "");
    if (!this.undercoverVehicle) {
      await this.loadVehicle();
      this.showLinks = true;
    } else {
      this.processObj = this.reformatTandrObjectforTransactionScreen(
        this.$store.getters.processObj
      );
      this.showLinks = false;
    }

    //intercepts pending transactions or expired registration
    if (!this.isFromORBack && this.pendingTransactionRequest) {
      if (this.pendingTransactionRequest.transaction.transactionType === "OR") {
        this.loadRenewalTransaction(undefined, this.pendingTransaction);
      } else {
        this.startTransaction(
          transactionTypes[
            this.pendingTransactionRequest.transaction.transactionType
          ],
          this.reformatTandrObjectforTransactionScreen(
            this.pendingTransactionRequest
          )
        );
      }
    } else if (
      !this.isFromORBack &&
      this.$store.getters.expRedir == false &&
      this.processObj.registration != undefined &&
      !this.undercoverVehicle &&
      this.processObj.courtesyRenewalsCount < 1 &&
      !this.processObj.hasIncomplete
    ) {
      const expDateCheck = new Date(
        this.processObj.registration.registrationExpiresDate.substr(0, 10)
      );
      const expDateCheckToday = new Date();
      let diff = Math.abs(expDateCheck.getTime() - expDateCheckToday.getTime());
      diff = diff / (1000 * 60 * 60 * 24);
      if (diff <= 90) {
        this.startTransaction("Registration Renewal");
        this.$store.commit("expRedir", true);
      }
    }
    window.scrollTo(0, 0);
    this.mainShow = true;
    this.$root.$emit("setLoading", false);
  }
};
</script>

<style scoped lang="scss">
$menu-width: 225px;

#detailsRight {
  margin-left: 35px;
  margin-right: 35px;
}

.top-buttons-div .top-button {
  margin-top: 30px;
}

.transaction-menu-container {
  width: $menu-width;
}

.vehicle-detail-container {
  margin-left: $menu-width;
}

.top-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.top-buttons-div button {
  margin-left: 5px;
}

.dialogMain {
  width: 95%;
  height: 90vh;
}

.float-foot-item {
  padding: 0px 20px;
}

#left {
  width: 250px;
}

h4 {
  font-size: 18pt;
  display: inline;
  margin-right: 25px;
}
</style>
