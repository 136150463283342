var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mainShow)?_c('div',{staticClass:"details"},[_c('v-dialog',{attrs:{"scrollable":"","value":_vm.showModal != 'false'},on:{"click:outside":_vm.hideModal}},[_c('v-card',{staticClass:"dialogMain"},[_c('div',{staticClass:"closeModalButton"},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.hideModal}},[_c('v-icon',[_vm._v("close")])],1)],1),(_vm.showModal == 'history')?_c('history',{attrs:{"vin":_vm.vin,"controlNo":_vm.controlNo},on:{"title-voided":_vm.loadVehicle,"editSucceeded":_vm.handleEditSuccess,"scanModalToggle":_vm.handleScanModalToggle}}):_vm._e(),(_vm.showModal == 'plateHistory')?_c('plateHistory',{attrs:{"isModal":"true"}}):_vm._e()],1)],1),_c('div',[_c('div',[(_vm.showLinks == true)?_c('div',{staticClass:"transaction-menu-container"},[_c('TransactionsMenu',{attrs:{"isFromORBack":_vm.isFromORBack}})],1):_vm._e(),_c('div',{staticClass:"vehicle-detail-container"},[_c('div',{attrs:{"id":"detailsRight"}},[_c('div',{staticClass:"top-buttons-div"},[(_vm.undercoverVehicle)?[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary top-button"},on),[_vm._v(" Remove Undercover ")])]}}],null,false,4176150510),model:{value:(_vm.showUndercoverDialog),callback:function ($$v) {_vm.showUndercoverDialog=$$v},expression:"showUndercoverDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Are you sure? ")]),_c('v-card-text',[_vm._v(" This will remove the undercover status from this vehicle. "),_c('br'),_c('v-text-field',{attrs:{"error":_vm.undercoverComment == undefined ||
                        _vm.undercoverComment.length == 0,"autofocus":"","messages":['required'],"label":"comment"},model:{value:(_vm.undercoverComment),callback:function ($$v) {_vm.undercoverComment=$$v},expression:"undercoverComment"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showUndercoverDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.undercoverComment == undefined ||
                        _vm.undercoverComment.length == 0,"text":""},on:{"click":_vm.undoUndercover}},[_vm._v(" I accept ")])],1)],1)],1)]:[_c('div',[_c('v-btn',{attrs:{"color":"blue"},on:{"click":function($event){_vm.showModal = 'history'}}},[_c('span',{staticClass:"white-text"},[_vm._v("History")])]),(_vm.processObj.registration != undefined)?_c('v-btn',{attrs:{"color":"blue"},on:{"click":_vm.showPlateHistory}},[_c('span',{staticClass:"white-text"},[_vm._v("Plate History")])]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue"}},on),[_c('span',{staticClass:"white-text"},[_vm._v(" Re-Print "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)])]}}],null,false,1495691390)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.reprint('201')}}},[_c('v-list-item-title',[_vm._v("Title Application Form (201)")])],1),(
                        _vm._.has(
                          _vm.processObj,
                          'registration.currentPlate.plateNo'
                        ) &&
                        _vm.processObj.registration.currentPlate.plateNo.length >
                          0
                      )?[_c('v-list-item',{on:{"click":function($event){return _vm.reprint('decal')}}},[_c('v-list-item-title',[_vm._v(" Registration Decal ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.reprint('decal', true)}}},[_c('v-list-item-title',[_vm._v(" Registration Decal (Letter Paper) ")])],1)]:_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.reprint('tdrSticker')}}},[_c('v-list-item-title',[_vm._v("TDR Sticker")])],1)],2)],1)],1)]],2),_c('h1',[_vm._v("Detailed Information")]),_c('div',{staticClass:"panel"},[_c('displayVehicleData',{attrs:{"processObj":_vm.processObj,"readOnly":true}}),(_vm._.has(_vm.processObj, 'vehicle.stops'))?_c('div',[_c('h4',[_vm._v("Stops")]),_c('div',{staticClass:"bootstrap"},[_c('b-table',{attrs:{"no-border-collapse":true,"selectable":"","select-mode":"single","responsive":"sm","hover":"","show-empty":true,"empty-text":"No Stops","fields":_vm.stopTableFields,"head-variant":"light","sort-icon-left":"","items":_vm.processObj.vehicle.stops},scopedSlots:_vm._u([{key:"cell(recordDate)",fn:function(data){return [(data.item.recordDate != undefined)?[_vm._v(" "+_vm._s(data.item.recordDate.substr(0, 10))+" ")]:_vm._e()]}}],null,false,2684372910)})],1)]):_vm._e()],1)])])]),_c('div',{staticClass:"floating-footer"},[_c('div',{staticClass:"float-foot-item float-foot-left"},[(_vm.$store.getters.resultsArray.length > 1)?[_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({ name: 'Results' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v("Results")])],1)]:_vm._e(),(_vm.previousRoute.name == 'MissingImages')?[_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$router.push({ name: 'MissingImages' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("arrow_back")]),_c('span',{staticClass:"white-text"},[_vm._v(" Missing Images ")])],1)]:_vm._e()],2)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }